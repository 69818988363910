<template>
  <v-dialog v-model="dialog" @click:outside="close" :retain-focus="false" max-width="600">
    <v-form ref="form" v-model="valid" :key="compoenentId">
      <v-card class="shadow-none pa-5 " flat>

        <section v-if="!isLoading">
          <v-row align="center">
            <v-col cols="12">
              <div class="d-flex justify-space-between align-center ">
                <h5 class="subtitle-7"> {{ $t(dialogTitle) }}</h5>
                <v-btn class="error--text" icon depressed @click="close">
                  <v-icon size="24" color="error"> mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>

            <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="عنوان الفئة عربى"
              :required="true" :isLoading="false" :cols="[12, 6, 6]" class="mt-1" />

            <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event"
              label="title category english" :required="true" :isLoading="false" :cols="[12, 6, 6]" class="mt-1" />

            <!-- Actions buttons -->
            <v-col cols="3" class="">
              <v-btn @click="close" depressed height="35" block color=" "
                class="rounded-lg font-weight-bold  red4--text mx-3" :loading="saveIsLoading">
                {{ $t('back') }}
              </v-btn>
            </v-col>

            <v-col cols="9">
              <v-btn @click="save" depressed height="35" block class="rounded-lg font-weight-bold white--text"
                color="blue6" :loading="saveIsLoading">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </section>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import GenericInput from '@/components/ui/GenericInput.vue';

export default {

  name: "QuickAddDialog",
  props: {
    dialog: { default: false },
    closeDialog: { default: Function },
    setQuickAdd: { default: Function },
    catSelectedRow: { default: Object },
  },
  data: () => ({
    valid: false,
    isLoading: false,
    saveIsLoading: false,
    dialogTitle: 'add',
    compoenentId: null,
    form: {
      name_en: null,
      name_ar: null,
    },
  }),
  mounted() { },
  computed: {

  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.compoenentId += 1
      }
    },
    catSelectedRow() {
      if (Object.keys(this.catSelectedRow).length) {
        this.dialogTitle = 'edit'
        this.compoenentId += 1
        this.form.name_en = this.catSelectedRow.name_en
        this.form.name_ar = this.catSelectedRow.name_ar
      }
    }
  },
  methods: {
    close() {
      this.closeDialog()
      this.dialogTitle = 'add'
      this.form = {
        name_en: null,
        name_ar: null,
      }
    },

    save() {
      if (!Object.keys(this.catSelectedRow).length) {
        this.saveIsLoading = true;
        this.$api.POST_METHOD('categories', this.form).then((response) => {
          this.saveIsLoading = false;
          if (response.check) {
            this.setQuickAdd({ ...response.data, name_ar: this.form.name_ar, name_en: this.form.name_en })
            this.$refs.form.resetValidation()
            this.$refs.form.reset()
            this.close()
          }
        })
      } else {
        this.saveIsLoading = true;
        this.form._method = 'PUT';
        this.$api.POST_METHOD(`categories/${this.catSelectedRow.id}`, this.form).then((response) => {
          this.saveIsLoading = false;
          if (response.check) {
            this.form.name = this.form[`name_${this.$i18n.locale}`]
            this.form.id = this.catSelectedRow.id
            delete this.form._method
            console.log('dd', this.form);
            this.setQuickAdd({ ...this.form })
            this.$refs.form.resetValidation()
            this.$refs.form.reset()
            this.close()
          }
        })
      }
    },



  },
  components: { GenericInput },

}
</script>

<style lang="scss" scoped></style>
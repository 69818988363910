// import Vue from "vue";
import axios from "axios";
// import VueAxios from "vue-axios";

const endpointURL = "https://testpos.joiasksa.com/api/v1/";
const accessToken = localStorage.getItem("access-token");
const api_token =
  "T13Lo5ownMeuaL8CXeO0EkZsMJkCiPCqzTivphIxCktKKOSHIW77EDKiHYorVI1wf0QgMklhzEQmknInqZvjX9AX6d4zFJVGYzNTwQ7awZAcPAdY8753ZjfU1wUWYgmVG791wYCXYAHDnh9ZjruKp4RRQkrd71P26olqUTnXrond64uCuGj7iA75PtMF1mOd5jVyELgq";
const lang = localStorage.getItem("language");
const branch = localStorage.getItem("currentbranch");

export default axios.create({
  baseURL: endpointURL + lang + "/",
  headers: {
    Accept: "application/json",
    "access-token": api_token,
    currentbranch: branch,
    Authorization: "Bearer " + accessToken,
  },
});

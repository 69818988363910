import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "../store";
import i18n from "./i18n";

export default {
  install(Vue) {
    Vue.prototype.$global = {
      ///////// Input Required Rules /////////
      requiredRule: [(v) => !!v || i18n.t("Field is required")],
      ///////// Input Email Required Rules /////////
      emailRule: [
        (v) => !!(v || "").match(/@/) || i18n.$t("Please enter email address"),
      ],
      ///////// Input Mobile Required Rules /////////
      mobileRule: [
        (v) =>
          !!(v || "").match(/^(?:[+0]9)?[0-9]{10,12}$/) ||
          i18n.t("Please enter mobile number"),
      ],
      ///////// Input Password Required Rules /////////
      passwordRule: [
        (v) => !!v || i18n.t("Password is required"),
        (v) =>
          (v && v.length >= 5) ||
          i18n.t("Password must be greater than 5 characters"),
      ],

      /////////  Array /////////
      FilterPermissions(Screen_Code) {
        if(store.state.permissions.length > 0){
          var find = store.state.permissions.filter(
            (obj) => obj["screen_code"] === Screen_Code
          );
          return find[0];
        }
      },
      CheckAction(Screen_Code, Action) {
        var find = store.state.permissions.filter(
          (obj) =>
            obj["screen_code"] === Screen_Code &&
            (obj["permission"].includes(Action.toString()) ||
              obj["permission"].includes(Number(+Action)))
        );
        return find.length > 0 ? true : false;
      },
      FilterArray(ARRAY, filterBy) {
        return ARRAY.filter((find) => find === filterBy);
      },
      FilterArrayOfObject(ARRAY, SearchIn, filterBy) {
        return ARRAY.filter((obj) => obj[SearchIn] === filterBy);
      },
      FilterArrayOfObjectElement(ARRAY, SearchIn, filterBy , ReturnElement = null) {
        const filter = ARRAY.filter((obj) => obj[SearchIn] === filterBy);
        return filter[0][ReturnElement];
      },
      FilterObjectByArray(OBJECT, ARRAY) {
        return ARRAY.reduce((obj, key) => ({ ...obj, [key]: OBJECT[key] }), {});
      },
      RemoveFromArray(ARRAY, SearchIn, VALUE) {
        const removeIndex = ARRAY.findIndex((find) => find[SearchIn] === VALUE);
        return ARRAY.splice(removeIndex, 1);
      },
      /////////   Calculate pagenation  /////////
      CalculateNumberOfPages(TOTAL, LIMIT) {
        return Math.ceil(TOTAL / LIMIT);
      },
      /////////  Numbers /////////
      NumberWithSpaces(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      ConvertToPercentage(PartialValue, TotalValue) {
        return ((100 * PartialValue) / TotalValue).toFixed(2);
      },
      ConvertFromPercentage(Percent, Total) {
        return ((Percent / 100) * Total).toFixed(2);
      },
      CalculatePriceTax(Price, Tax) {
        return {
          price: Number(+Price),
          tax: Number(+Tax),
          priceAfterTax: Number(+Price) + Number(+Price) * Number(+Tax),
          pricePercentage: 100 - 100 * Number(+Tax),
          taxPercentage: 100 * Number(+Tax),
        };
      },
      RyalFormatter(NUMBER) {
        var formatter = new Intl.NumberFormat("ar", {
          style: "currency",
          currency: "SAR",
        });

        return formatter.format(NUMBER);
      },
      FILE_TYPE(FILE) {
        // Base64
        // console.log(FILE);
        if (String(FILE).substring(0, 5) == "data:") {
          console.log(
            "FILE TYPE =>",
            FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
          );
          switch (FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              return "excel";
            case "application/vnd.ms-excel":
              return "excel";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "word";
            case "application/octet-stream":
              return "rar";
            case "application/x-zip-compressed":
              return "zip";
            case "application/json":
              return "json";
            case "application/pdf":
              return "pdf";
            case "image/png":
              return "png";
            case "image/jpg":
              return "jpg";
            case "image/jpeg":
              return "jpeg";
            case "text/plain":
              return "txt";
            case "image/svg+xml":
              return "svg";
            case "image/gif":
              return "gif";
            case "video/mp4":
              return "mp4";
            case "video/ogg":
              return "mp4";
            case "video/quicktime":
              return "quicktime";
            case "video/mov":
              return "mov";
            default:
              return "notFound";
          }
        }
        // Normal
        else {
          switch (FILE.type) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              return "excel";
            case "application/vnd.ms-excel":
              return "excel";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "word";
            case "application/octet-stream":
              return "rar";
            case "application/x-zip-compressed":
              return "zip";
            case "application/json":
              return "json";
            case "application/pdf":
              return "pdf";
            case "image/png":
              return "png";
            case "image/jpg":
              return "jpg";
            case "image/jpeg":
              return "jpeg";
            case "text/plain":
              return "txt";
            case "image/svg+xml":
              return "svg";
            case "image/gif":
              return "gif";
            case "video/mp4":
              return "mp4";
            case "video/ogg":
              return "mp4";
            case "video/quicktime":
              return "quicktime";
            case "video/mov":
              return "mov";
            default:
              var n = FILE.lastIndexOf(".");
              var result = FILE.substring(n + 1);
              return result;
          }
        }
      },
      Currency(NUMBER) {
        return NUMBER.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      },
      NumberWithCommas(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      IncrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            find[ATTRIBUTE] = find[ATTRIBUTE] + 1;
          }
        });
      },
      DecrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            if (find[ATTRIBUTE] > 1) {
              find[ATTRIBUTE] = find[ATTRIBUTE] - 1;
            }
          }
        });
      },
      DecimalFormatter(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      ///////// Decryption / Encryption /////////
      CRYPT(KEY, TEXT) {
        // 1
        let textEncrypt =   Vue.prototype.$CryptoJS.enc.Base64.stringify(
          Vue.prototype.$CryptoJS.enc.Utf8.parse(TEXT)
        );
        
        return textEncrypt

        // 2
        // var x  = Vue.prototype.$CryptoJS.enc.Hex.parse(KEY);
        // var iv   = Vue.prototype.$CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
        // var encrypted = Vue.prototype.$CryptoJS.AES.encrypt(TEXT, x, {iv: iv});
        // return encrypted.toString();

      },
      DECRYPT(KEY, ENCODED) {
        // 1
        let textDecrypt = Vue.prototype.$CryptoJS.enc.Base64.parse(ENCODED).toString(
          Vue.prototype.$CryptoJS.enc.Utf8
        );
        return textDecrypt
        // 2
        // var x  = Vue.prototype.$CryptoJS.enc.Hex.parse(key);
        // var iv   = Vue.prototype.$CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
        // var decrypted = Vue.prototype.$CryptoJS.AES.decrypt(encryptedString, x,{iv:iv});
        // return decrypted.toString();

      },

      ///////// Alert Toast /////////
      ShowAlert(TYPE, TITLE, TOAST = true, MSG, TIMER = 3000) {
        Swal.fire({
          icon: TYPE,
          title: TITLE,
          text: MSG,
          toast: TOAST,
          timer: TIMER,
          position:
            TOAST == true
              ? store.state.Settings.translation.rtl == true
                ? "top-start"
                : "top-end"
              : "center",
          showConfirmButton: false,
        });
      },


      FormData(DATA) {
        const formData = new FormData();
        for (const key in DATA) {
          if (Array.isArray(DATA[key]) && DATA[key].length) {
            DATA[key].forEach((attachment, index) => {
              if (typeof attachment === 'object' && !(attachment instanceof File)) {
                for (let obj in attachment) {
                  formData.append(`${key}[${index}].${obj}`, attachment[obj]);
                }
              } else {
                formData.append(`${key}[${index}]`, attachment);
              }
            })
          }
          else if (typeof DATA[key] === 'object' && DATA[key] !== null && !(DATA[key] instanceof File)) {
            for (const subKey in DATA[key]) {
              if (Array.isArray(DATA[key][subKey]) && DATA[key][subKey].length) {
                DATA[key][subKey].forEach((attachment, index) => {
                  if (typeof attachment === 'object' && !(attachment instanceof File)) {
                    for (let obj in attachment) {
                      formData.append(`${key}.${subKey}[${index}].${obj}`, attachment[obj]);
                    }
                  } else {
                    formData.append(`${key}.${subKey}[${index}]`, attachment);
                  }
                })
              } else {
                DATA[key][subKey] && formData.append(`${key}.${subKey}`, DATA[key][subKey])
              }
            }
          }
          else {
            if (Array.isArray(DATA[key]) && !DATA[key].length) {
              false
            } else {
              formData.append(key, DATA[key])
            }
          }
        }

        return formData
      },
    };
  },
};

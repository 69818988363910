<template>
  <v-card class="shadow rounded-lg justify-center my-0">
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th>
              <v-checkbox
                class="ma-auto font-weight-black caption"
                :key="activeTab"
                v-model="allAccess"
                :label="activeTab"
                v-on:click="toggleAllPermissions()"
                color="indigo darken-2"
                hide-details
              ></v-checkbox>
            </th>
            <th>
              <v-checkbox
                class="ma-auto caption"
                v-model="viewAll"
                :label="$i18n.t('view')"
                v-on:click="(event) => selectAllRow('view', viewAll)"
                color="indigo darken-2"
                hide-details
              ></v-checkbox>
            </th>
            <th>
              <v-checkbox
                class="ma-auto caption"
                v-model="addAll"
                :label="$i18n.t('add')"
                v-on:click="(event) => selectAllRow('add', addAll)"
                color="indigo darken-2"
                hide-details
              ></v-checkbox>
            </th>
            <th>
              <v-checkbox
                class="ma-auto caption"
                v-model="editAll"
                :label="$i18n.t('Edit')"
                v-on:click="(event) => selectAllRow('edit', editAll)"
                color="indigo darken-2"
                hide-details
              ></v-checkbox>
            </th>
            <th>
              <v-checkbox
                class="ma-auto caption"
                v-model="deleteAll"
                :label="$i18n.t('delete')"
                v-on:click="(event) => selectAllRow('delete', deleteAll)"
                color="indigo darken-2"
                hide-details
              ></v-checkbox>
            </th>
            <th>
              <v-checkbox
                class="ma-auto caption"
                v-model="forceDeleteAll"
                :label="$i18n.t('force delete')"
                v-on:click="(event) => selectAllRow('forceDelete', forceDeleteAll)"
                color="indigo darken-2"
                hide-details
              ></v-checkbox>
            </th>
            <th>
              <v-checkbox
                class="ma-auto caption"
                v-model="printAll"
                :label="$i18n.t('print')"
                v-on:click="(event) => selectAllRow('print', printAll)"
                color="indigo darken-2"
                hide-details
              ></v-checkbox>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in data" :key="index">
            <td>
              <!-- {{ row.main_title }} -->
              <v-checkbox
                class="ma-auto font-weight-bold"
                input-value="true"
                v-if="((row.view && row.add && row.edit && row.print && row.delete && row.forceDelete) || row.allSelected)"
                v-on:click="(event) => selectAllRowCols(row.id, false)"
                :label="row.sub_title"
                color="indigo darken-2"
                hide-details
              ></v-checkbox>
              <span v-if="row.availableActions.forEach((ac) => row[ac] == true)">true</span>
              <v-checkbox
                class="ma-auto font-weight-bold"
                v-if="!((row.view && row.add && row.edit && row.print && row.delete && row.forceDelete) || row.allSelected)"
                v-on:click="(event) => selectAllRowCols(row.id, true)"
                :label="row.sub_title"
                color="indigo darken-2"
                hide-details
              ></v-checkbox>
            </td>
            <td>
              <v-switch
                v-if="row.actions.some(e => e == 1)"
                inset
                color="indigo darken-2"
                hide-details
                class="ma-auto"
                v-model="row.view"
                @change="actionClicked(row, index, 'view')"
              ></v-switch>
            </td>
            <td>
              <v-switch
                v-if="row.actions.some(e => e == 2)"
                inset
                @change="actionClicked(row, index, 'add')"
                color="indigo darken-2"
                hide-details
                class="ma-auto"
                v-model="row.add"
              ></v-switch>
              <v-divider v-else width="40" class="mx-2"></v-divider>
            </td>
            <td>
              <v-switch
                v-if="row.actions.some(e => e == 3)"
                inset
                @change="actionClicked(row, index, 'edit')"
                color="indigo darken-2"
                hide-details
                class="ma-auto"
                v-model="row.edit"
              ></v-switch>
              <v-divider v-else width="40" class="mx-2"></v-divider>
            </td>
            <td>
              <v-switch
                v-if="row.actions.some(e => e == 4)"
                inset
                @change="actionClicked(row, index, 'delete')"
                color="indigo darken-2"
                hide-details
                class="ma-auto"
                v-model="row.delete"
              ></v-switch>
              <v-divider v-else width="40" class="mx-2"></v-divider>
            </td>
            <td>
              <v-switch
                v-if="row.actions.some(e => e == 6)"
                inset
                @change="actionClicked(row, index, 'forceDelete')"
                color="indigo darken-2"
                hide-details
                class="ma-auto"
                v-model="row.forceDelete"
              ></v-switch>
              <v-divider v-else width="40" class="mx-2"></v-divider>
            </td>
            <td>
              <v-switch
                v-if="row.actions.some(e => e == 5)"
                inset
                @change="actionClicked(row, index, 'print')"
                color="indigo darken-2"
                hide-details
                class="ma-auto"
                v-model="row.print"
              ></v-switch>
              <v-divider v-else width="40" class="mx-2"></v-divider>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>








<script>

export default {
  name: "PermissionsDataTable",
  computed: {},
  mounted() {
  //  check permission on component mounted
    this.checkAllPermissions()
    this.checkHeaderCheckbox('view')
    this.checkHeaderCheckbox('add')
    this.checkHeaderCheckbox('edit')
    this.checkHeaderCheckbox('delete')
    this.checkHeaderCheckbox('print')
    this.checkHeaderCheckbox('forceDelete')
  },
  watch: {
    //  check permission every active tab changes
    activeTab() {
      this.checkAllPermissions()
      this.checkHeaderCheckbox('view')
      this.checkHeaderCheckbox('add')
      this.checkHeaderCheckbox('edit')
      this.checkHeaderCheckbox('delete')
      this.checkHeaderCheckbox('print')
      this.checkHeaderCheckbox('forceDelete')
    }
  },
  methods: {
    // Follow My comments i hope to help you :) <3
    actionClicked(row, rowIndex, action) {
      // Check if action value == true , so (view) must be equal true
      if (action != 'view' && this.data[rowIndex][action] == true && (this.data[rowIndex].view == false || this.data[rowIndex].view == null)) {
        this.data[rowIndex].view = true
      }
      // Check if view == false , so other action must be false
      if (action == 'view' && this.data[rowIndex][action] == false) {
        this.data[rowIndex].view = false,
          this.data[rowIndex].add = false,
          this.data[rowIndex].delete = false,
          this.data[rowIndex].print = false,
          this.data[rowIndex].forceDelete = false,
          this.data[rowIndex].edit = false
      }
      // check if all action true to set all selected true (use it in Checkbox in this row)
      this.data[rowIndex].availableActions.every(action => {
        this.data[rowIndex].allSelected = this.data[rowIndex][action] ? this.data[rowIndex][action] : false
        return this.data[rowIndex][action] ? this.data[rowIndex][action] : false
      });
      //  checkAllPermissions 
      this.checkAllPermissions()
      this.checkHeaderCheckbox('view')
      this.checkHeaderCheckbox('add')
      this.checkHeaderCheckbox('edit')
      this.checkHeaderCheckbox('delete')
      this.checkHeaderCheckbox('print')
      this.checkHeaderCheckbox('forceDelete')
      // Push row to changed permissions
      this.setChanges(this.data[rowIndex])
    },
    // ---------------------------------------------------------------------------------------
    setChanges(changes) {
      // check changed row if it exist in changed permissions array
      if (!this.allChanges.some((oldPermission) => oldPermission.id === changes.id)) {
        // not found so i push it
        this.allChanges.push(changes);
      } else {
        // found it in changed permissions array
        // loop to get this permission to update it by new values
        this.allChanges.forEach((oldPermission) => {
          if (oldPermission.id === changes.id) {
            oldPermission = changes
          }
        });
      }
      // finally back allChanges array to parent component
      this.backValueMethod(this.allChanges)
    },
    // ---------------------------------------------------------------------------------------
    checkAllPermissions() {
      // Check if he has all permission
      this.allAccess = false;
      this.data.forEach((permission) => {
        permission.allSelected = false
        permission.availableActions.every(action => {
          // check if all action true to set all selected true (use it in Checkbox in this row) && (Selected all checkbox)
          return (
            permission.allSelected = permission[action] ? true : false,
            permission[action] ? permission[action] : false,
            this.allAccess = permission[action] ? true : false,
            this.viewAll = permission[action] ? true : false,
            this.addAll = permission[action] ? true : false,
            this.editAll = permission[action] ? true : false,
            this.deleteAll = permission[action] ? true : false,
            this.forceDeleteAll = permission[action] ? true : false,
            this.printAll = permission[action] ? true : false
          )
        });
      }, 0);

    },
    // ---------------------------------------------------------------------------------------
    checkHeaderCheckbox(checkboxAction) {
      this.data.forEach((permission) => {
        if (checkboxAction == 'view' && permission[checkboxAction] == false) {
          this.viewAll = false;
        }
        if (checkboxAction == 'edit' && permission[checkboxAction] == false) {
          this.editAll = false;
        }
        if (checkboxAction == 'add' && permission[checkboxAction] == false) {
          this.addAll = false;
        }
        if (checkboxAction == 'delete' && permission[checkboxAction] == false) {
          this.deleteAll = false;
        }
        if (checkboxAction == 'print' && permission[checkboxAction] == false) {
          this.printAll = false;
        }
        if (checkboxAction == 'forceDelete' && permission[checkboxAction] == false) {
          this.forceDeleteAll = false;
        }
      })
    },
    // ---------------------------------------------------------------------------------------
    toggleAllPermissions() {
      this.data.forEach((permission) => {
        return (
          // loop in screen available action and set status
          permission.availableActions.forEach(action => {
            permission[action] = this.allAccess;
            permission.allSelected = this.allAccess,
              this.viewAll = this.allAccess,
              this.addAll = this.allAccess,
              this.editAll = this.allAccess,
              this.deleteAll = this.allAccess,
              this.forceDeleteAll = this.allAccess,
              this.printAll = this.allAccess,
              this.setChanges(permission)
          })
        )
      }, 0);
    },
    // ---------------------------------------------------------------------------------------
    selectAllRowCols(id, status) {
      const objIndex = this.data.findIndex((row => row.id == id))
      // loop in screen available action and set status
      this.data[objIndex].availableActions.forEach(action => {
        this.data[objIndex][action] = status
      });

      this.setChanges(this.data[objIndex])
      this.checkAllPermissions();
      this.checkHeaderCheckbox('view')
      this.checkHeaderCheckbox('add')
      this.checkHeaderCheckbox('edit')
      this.checkHeaderCheckbox('delete')
      this.checkHeaderCheckbox('print')
      this.checkHeaderCheckbox('forceDelete')
      return this.data
    },
    // ---------------------------------------------------------------------------------------
    selectAllRow(group, status) {
      // console.log(group, status);
      if (group == "view" && status == false) {
        this.data.forEach((permission) => {
          return (
            permission['view'] = status,
            this.viewAll = status,
            permission['add'] = status,
            this.addAll = status,
            permission['delete'] = status,
            this.deleteAll = status,
            permission['print'] = status,
            this.printAll = status,
            permission['forceDelete'] = status,
            this.forceDeleteAll = status,
            permission['edit'] = status,
            this.editAll = status,
            this.setChanges(permission)
          )
        }, 0);
      }
      else {
        if (group !== 'view' && status == true) {
          this.data.forEach((permission) => {
            return (permission.availableActions.some((action) => action === group) ? permission[group] = status : '', permission['view'] = status, this.setChanges(permission))
          }, 0);
        } else {
          this.data.forEach((permission) => {
            return (permission[group] = status, this.setChanges(permission))
          }, 0);
        }
      }
      this.checkAllPermissions();
      this.checkHeaderCheckbox('view')
      this.checkHeaderCheckbox('add')
      this.checkHeaderCheckbox('edit')
      this.checkHeaderCheckbox('delete')
      this.checkHeaderCheckbox('print')
      this.checkHeaderCheckbox('forceDelete')
    },




  },

  props: {
    backValueMethod: { type: Function },
    isLoading: { default: true },
    data: { default: [] },
    header: { default: [] },
    options: { default: [] },
    activeTab: { default: "" },

  },


  data() {
    return {
      search: '',
      viewAll: false,
      addAll: false,
      editAll: false,
      deleteAll: false,
      forceDeleteAll: false,
      printAll: false,
      allAccess: false,
      selected: [],
      allChanges: []
    };
  },
};
</script>

<template>
  <section>
    <v-container fluid>

      <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="!disabled" :deleteMethod="deleteMethod" />

      <!-- Form -->
      <v-form ref="form" v-model="valid" class="pa-3" :key="componentId">
        <v-card class="backgroundW shadow pa-5 pt-7">
          <v-row align="center">

            <GenericInput type="text" :value="form.title_ar" @input="form.title_ar = $event" label="عنوان عربى"
              :required="true" :isLoading="false" :cols="[12, 4, 4]" class="mt-1" />

            <GenericInput type="text" :value="form.title_en" @input="form.title_en = $event" label="English title"
              :required="true" :isLoading="false" :cols="[12, 4, 4]" class="mt-1" />

            <GenericInput type="select" :lookups="categories" :value="form.category_id"
              @input="form.category_id = $event" label="category" :multi="false" :required="true" selected_label="name"
              selected_prop="id" :isLoading="pageData.isLoading" :cols="[12, 4, 4]">
              <template v-slot:append>
                <v-btn icon class="mt-n1 mx-n2" color="success" @click="quickAddDialog = true">
                  <v-icon>mdi-plus-box</v-icon>
                </v-btn>
              </template>

              <template v-slot:selection="{ item }">
                {{ item.name }}
              </template>

              <template v-slot:item="{ item }">
                <div class="cat-item">
                  <span> {{ item.name }}</span>
                  <span @click.stop>
                    <v-icon @click="selecteCategoryEdit(item)" class="mx-2" color="gray6" size="18">mdi-pencil</v-icon>
                    <v-icon @click="categoryDeleteMethod(item)" color="red" size="18">mdi-delete</v-icon>
                  </span>
                </div>
              </template>
            </GenericInput>

            <GenericInput type="textarea" :value="form.short_description_ar" @input="form.short_description_ar = $event"
              label="وصف مختصر عربى" :required="true" :isLoading="false" :cols="[12, 6, 6]" class="mt-1" />

            <GenericInput type="textarea" :value="form.short_description_en" @input="form.short_description_en = $event"
              label="short description english" :required="true" :isLoading="false" :cols="[12, 6, 6]" class="mt-1" />

            <GenericInput type="texteditor" :value="form.description_ar" @input="form.description_ar = $event"
              label="الوصف عربى" :required="true" :isLoading="false" :cols="[12, 6, 6]" class="mt-2"
              :inputDesign="true" />

            <GenericInput type="texteditor" :value="form.description_en" @input="form.description_en = $event"
              label="Details english" :required="true" :isLoading="false" :cols="[12, 6, 6]" class="mt-2"
              :inputDesign="true" />

            <v-col cols="12">
              <FileInput type="file" label="image" :value="form.image" placeholder="select image" :hideImage="false"
                prependIcon="mdi-camera" @input="form.image = $event" :required="true" :multi="false" :base64="false"
                :cols="[12, 6, 6]" class="mt-2" />
            </v-col>

          </v-row>
        </v-card>

      </v-form>
      <!-- End of Form -->
    </v-container>
    <CustomModal :buttonOneLoading="saveDataLoading" :dialog="deleteCatDialog" :opacity="0.6"
      :buttonOneMethod="() => selecteCategoryDelete()"
      :buttonTwoMethod="() => (deleteCatDialog = false, catSelectedRow = {})" :dialogContent="{
        title: 'are you sure you want to delete',
        selectedTitle: catSelectedRow.name,
        buttonOneTitle: 'yes',
        buttonOneColor: 'primary',
        buttonTwoTitle: 'no',
        buttonTwoColor: 'error',
      }" />

    <QuickAddDialog :dialog="quickAddDialog" :closeDialog="() => (quickAddDialog = false, catSelectedRow = {})"
      :setQuickAdd="setNewAdd" :catSelectedRow="catSelectedRow" />
  </section>
</template>


<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
import QuickAddDialog from './QuickAddDialog.vue';
import FileInput from '@/components/ui/FileInput.vue';
import CustomModal from "@/components/modals/CustomModal.vue";


export default {
  name: "BlogControl",

  data: () => ({
    pageData: {
      screen_code: "02-014",
      url: null,
      controlRoute: "/website/blog-control/",
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      isEdit: false,
      editIsLoading: false,
    },
    valid: false,
    form: {
      title_ar: null,
      title_en: null,
      category_id: null,
      description_ar: null,
      description_en: null,
      short_description_ar: null,
      short_description_en: null,
      image: null,
    },
    quickAddDialog: false,
    deleteCatDialog: false,
    saveDataLoading: false,
    categories: [],
    catSelectedRow: {},
    componentId: 0,


  }),

  computed: {
    disabled() {
      return (
        Object.keys(this.form).some(key => !this.form[key])
      );
    }
  },
  mounted() {
    this.pageMainData()
    this.getData()
  },
  watch: {

  },
  methods: {

    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
      this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
    },
    getData() {
      if (this.$route.params.id) {
        if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
          this.pageData.isEdit = true;
          this.pageData.editIsLoading = true;
          this.pageData.isLoading = true;
          this.$api.GET_METHOD(`blogs/${this.$route.params.id}`).then((response) => {
            this.pageData.editIsLoading = false;
            this.pageData.isLoading = false;
            if (response.check) {
              console.log("response", response);
              this.categories = response.data.categories
              Object.keys(this.form).forEach(key => {
                this.form[key] = response.data.data[key]
              });
              // Check if category_id is not included in this.categories
              if (!this.categories.includes(this.form.category_id)) {
                this.form.category_id = null;
              }
              this.$refs.form.validate();
            }
          })
        } else {
          this.$router.push('/')
        }
      } else {
        if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

          this.pageData.isLoading = true;
          this.$api.GET_METHOD(`blogs/create`).then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.categories = response.data.categories
            }
          })
        } else {
          this.$router.push('/')
        }

      }

    },
    save() {
      if (this.$refs.form.validate()) {
        this.pageData.isLoading = true;
        if (this.pageData.isEdit == true) {
          let requestBody = { ...this.form, _method: 'PUT' };

          // requestBody.image = this.form?.image?.substring(0, 7) == '/upload' ? null : this.form?.image;
          this.$api.POST_METHOD(`blogs/${this.$route.params.id}`, this.$global.FormData(requestBody)).then((response) => {
            this.pageData.isLoading = false;
            if (response.check == true) {
              this.$router.push(this.pageData.url)
            }
          })
        }
        else {
          this.$api.POST_METHOD(`blogs`, this.$global.FormData(this.form)).then((response) => {
            this.pageData.isLoading = false;
            if (response.check == true) {
              this.$router.push(this.pageData.url)
            }
          })
        }
      }
    },
    deleteMethod(status) {
      if (status == true) {
        this.pageData.isLoading = true
        this.$api.POST_METHOD_MULTIPART(`blogs/${this.$route.params.id}`, { _method: 'delete' }).then(() => {
          this.$router.push(this.pageData.url)
          this.pageData.isLoading = false
        })
      }
    },
    setNewAdd(newItem) {
      const index = this.categories.findIndex(item => item.id === newItem.id);
      if (index != -1) {
        this.categories[index] = newItem;
      } else {
        this.categories.push({ ...newItem });
      }
      this.form.category_id = newItem.id
      this.componentId += 1
    },
    selecteCategoryEdit(item) {
      this.catSelectedRow = item;
      this.quickAddDialog = true
      console.log(item);
    },
    categoryDeleteMethod(item) {
      this.catSelectedRow = item;
      this.deleteCatDialog = true;
    },
    selecteCategoryDelete() {
      this.saveDataLoading = true;
      this.$api.POST_METHOD(`categories_toggle/${this.catSelectedRow.id}`, null).then((response) => {
        this.saveDataLoading = false;
        this.deleteCatDialog = false;
        if (response) {
          this.categories = this.categories.filter(category => category.id != this.catSelectedRow.id);
          this.form.category_id = null;
        }
      })
    },

  },

  components: {
    ControlHeader,
    GenericInput,
    QuickAddDialog,
    FileInput,
    CustomModal
  },

};
</script>

<style scoped lang="scss">
#userSelections {
  .v-chip.v-size--small {
    display: none !important;
  }
}

.cat-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
<template>
  <v-col cols="12" class="d-flex align-center justify-center">
    <vue-excel-editor v-model="jsondata" >
      <vue-excel-column field="user" label="id" width="120px" type="string" text-transform="upppercase " />
      <vue-excel-column field="name" label="Name" width="120px" type="string" />
      <vue-excel-column field="phone" label="Contact" width="120px" type="string" />
      <vue-excel-column field="gender" label="Gender" width="120px" type="select" :options="['F','M','U']" />
      <vue-excel-column field="age" label="Age" width="120px" type="number" />
      <vue-excel-column field="birth" label="date" width="120px" type="date" />
    </vue-excel-editor>
  </v-col>
</template>



<style scoped lang="scss">

</style>



<script>


export default {
  name: "ExcelTable",
  props: {
    returnMethod: { type: Function },
    data: { default: [] },
    headers: { default: [] },
    isLoading: { default: [] },
  },
  computed: {},
  data: () => ({
    jsondata: [
      {
        user: '123d',
        name: 'asdasd',
        phone: 'vvv',
        gender: '1',
        age: '43',
        birth: '2022-08-09',
      },
      {
        user: '123d',
        name: 'asdasd',
        phone: 'vvv',
        gender: '1',
        age: '43',
        birth: '2022-08-09',
      },
      {
        user: '123d',
        name: 'asdasd',
        phone: 'vvv',
        gender: '1',
        age: '43',
        birth: '2022-08-09',
      },
      {
        user: '123d',
        name: 'asdasd',
        phone: 'vvv',
        gender: '1',
        age: '43',
        birth: '2022-08-09',
      },
      {
        user: '123d',
        name: 'asdasd',
        phone: 'vvv',
        gender: '1',
        age: '43',
        birth: '2022-08-09',
      },
    ]
  }),
  methods: {
    closeDialog() {
      this.returnMethod();
    },
    download() {

    },
    uploadExcel() {

    },
  },
  mounted() {
  },
  components: {

  }
};
</script>

<template>
  <div class="d-flex align-center">
    <v-file-input v-model="input" rounded-md :loading="isLoading" :solo="solo" outlined :reverse="reverse"
      :dense="dense" :name="name" @keydown.enter="keydownEnter()" :class="classes" :prepend-icon="prependIcon"
      :rules="required == true ? $global.requiredRule : $global.notRequiredRule" @change="onImageChange($event)"
      :multiple="multi ? true : false" :disabled="disabled" :color="color" :background-color="background"
      :append-icon="icon" chips :hide-details="hideDetails == true ? 'auto' : false" small-chips truncate-length="20"
      :placeholder="$i18n.t(placeholder)" :error-messages="$i18n.t(errorMessage)" :persistent-hint="hint ? true : false"
      :hint="$i18n.t(hint)">
      <template v-slot:label>
        <label v-if="inputDesign == 'default'">
          {{ $t(label) }}
          <span class="error--text" v-if="required == true">*</span>
        </label>
      </template>
      <template v-slot:selection>
        <span v-if="input">{{ $t('File is loaded , you can delete or update it') }}</span>
      </template>
      <template v-slot:append-outer>
        <slot name="append-outer" />
      </template>
      <template v-slot:append>
        <slot name="append" />
      </template>
      <template v-slot:prepend-inner>
        <slot name="prepend-inner" />
      </template>
      <template v-slot:prepend>
        <slot name="prepend" />
      </template>
    </v-file-input>
    <v-menu offset-y open-on-hover v-if="input && !hideImage">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" color="secondary" icon fab x-small v-bind="attrs" v-on="on">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <div v-if="!hideImage && multi != true && input" class="overflow-hidden rounded-lg my-2">
            <div v-lazy-container="{ selector: 'img' }"
              v-if="fileExtension == 'jpeg' || fileExtension == 'jpg' || fileExtension == 'png' || fileExtension == 'gif' || fileExtension == 'svg'">
              <img class="d-block ma-auto" style="max-height: 200px;" :key="input" :data-src="filePreview"
                alt="Kader" />
            </div>
            <div v-else>
              <img v-if="fileExtension !== 'pdf' && fileExtension !== 'excell'"
                :src="require(`@/assets/img/png/files/${fileExtension}.png`)" height="200" class="d-block ma-auto"
                alt="Kader" />
              <div v-if="fileExtension == 'pdf'">
                <iframe width='100%' height='350' :src="filePreview" />
              </div>
              <div v-if="fileExtension == 'excell'">
                <ExcelTable />
              </div>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import ExcelTable from '../ui/ExcelTable.vue';
export default {
  name: "FileInput",
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: null },
    value: { default: null },
    required: { default: false },
    dense: { default: true },
    onChange: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    color: { default: "secondary" },
    background: { default: null },
    icon: { default: "" },
    prependIcon: { default: "" },
    hideDetails: { default: false },
    inputDesign: { default: false },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    fileName: { type: Function },
    placeholder: { default: null },
    solo: { default: false },
    classes: { default: null },
    dir: { default: null },
    reverse: { default: false },
    multi: { default: false },
    errorMessage: { default: null },
    hint: { default: null },
    base64: { default: true },
  },
  data: () => ({
    selectedImages: [],
    selectedImage: null,
  }),
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    fileExtension() {
      if (this.input) {
        return this.$global.FILE_TYPE(this.input);
      } else {
        return null
      }
    },

    filePreview() {
      if (this.input instanceof File) {
        return URL.createObjectURL(this.input);
      } else if (typeof this.input === 'string' && this.input.startsWith('data:image')) {
        // If input is a base64 string representing an image
        return this.input;
      } else {
        // Handle other cases, e.g., URLs or other types of inputs
        return this.$api.serverUrl + this.input;
      }
    },
    typeFile() {
      return this.input instanceof File
    }
  },
  watch: {

  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    async onImageChange(image) {
      if (this.multi) {
        let files_name = []
        image.forEach(file => {
          let file_name = file.name.split(/[\\/]/g).pop().split('.')[0]
          files_name.push(file_name);
        });
        console.log('file', files_name);
        this.$emit("fileName", files_name);
      } else {
        let file_name = image.name.split(/[\\/]/g).pop().split('.')[0]
        console.log('file', file_name);
        this.$emit("fileName", file_name);
        this.fileName(file_name);
      }

      if (this.base64) {
        var reader = new FileReader();
        let multiImage = this.multi;
        var base64arr = [];
        var images = new Promise(function (resolve, reject) {
          reader.readAsDataURL(image);
          console.log('eslam', reader);
          reader.onload = function () {
            resolve(reader.result);
          };
          reader.onerror = function (error) {
            console.log("Error: ", error);
            reject(error);
            alert(error);
          };
        });
        if (await images) {
          if (multiImage == true) {
            base64arr.push(await images);
            this.selectedImages.push(await images);
            // console.log('aaa', this.selectedImages);
            this.$emit("input", this.selectedImages);
            console.log('selectedImages', this.selectedImages);
            this.onClickOuter(
              this.name,
              this.input,
              this.selectedImages.toString(),
              this.type,
              this.required
            );
          }
          if (multiImage == null || multiImage == false) {
            this.selectedImage = await images;
            // console.log('bbbb', this.selectedImage);
            this.$emit("input", this.selectedImage);
            console.log('selectedImages 2', this.selectedImage);

            this.onClickOuter(
              this.name,
              this.input,
              this.selectedImage,
              this.type,
              this.required
            );
            this.$refs.myVueDropzone.disable();
          }
        }
      }
      else {
        this.$emit("input", image);
      }
    },
  },
  components: {
    ExcelTable,
  }
};
</script>



<style scoped lang="scss"></style>

import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
// Views :-
import Dashboard from "@/views/Dashboard/Dashboard";
import Auth from "@/views/Auth/Auth";
import LoginPage from "@/views/Auth/LoginPage";
import ForgetPassword from "@/views/Auth/ForgetPassword";
import Error500 from "@/views/Errors/Error500";
import SystemUpdate from "@/views/Errors/SystemUpdate";
import Error404 from "@/views/Errors/Error404";

// Modules Children Routes :-

import { DashboardRoutes } from "@/router/DashboardRoutes";

Vue.use(VueRouter);

// Routes :-
const routes = [
  // Authorized :-
  {
    path: "/",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      store.dispatch("checkAppVersion").then();
      store.dispatch("checkAuth").then();
      console.log("beforeEnter and checkAuth", store.state.isAuth);
      if (store.state.isAuth === true) {
        store.dispatch("getUserData").then();
        next();
      } else {
        router.push("/login").then();
      }
    },
    children: [...DashboardRoutes],
  },
  // Not Authorized :-
  {
    path: "/auth",
    component: Auth,
    children: [
      {
        path: "/",
        name: "LoginPageAuth",
        component: LoginPage,
        meta: {
          title: "login",
        },
      },
      {
        path: "/forget-password",
        name: "ForgetPassword",
        component: ForgetPassword,
        meta: {
          title: "Forget Password",
        },
      },
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
        meta: {
          title: "login",
        },
      },
    ],
  },
  // Error Pages  :-
  {
    path: "/error500",
    name: "Error500",
    component: Error500,
  },
  {
    path: "/system_update",
    name: "SystemUpdate",
    component: SystemUpdate,
  },
  {
    path: "*",
    name: "Error404",
    component: Error404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (
      err.name !== 'NavigationDuplicated' &&
      !(
        err.message.includes('Navigation cancelled') ||
        err.message.includes('Avoided redundant navigation') ||
        err.message.includes('Navigating to current location')
      )
    ) {
      throw err;
    }
  });
};

router.beforeEach((to, from, next) => {
  store.commit("SET_ROUTE", to);
  next();
});

export default router;
